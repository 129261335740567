import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Identities } from "./screens/identities";
import Login from "./screens/login";
import { ProtectedRoute, UnProtectedRoute } from "./components/protectedRoute";
import { Error404 } from "./screens/Error404";
import { Identity } from "./screens/identity";
import "./index.css";
import { FishGallery } from "./screens/fish_gallery";
import { Users } from "./screens/users/users";
import { FishOrders } from "./screens/orders";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Identities />
            </ProtectedRoute>
          }
        />
        <Route
          path="/code/:code"
          element={
            <ProtectedRoute>
              <Identity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <UnProtectedRoute>
              <Login />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />

        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <FishOrders />
            </ProtectedRoute>
          }
        />
        <Route path="/fish-gallery/:id" element={<FishGallery />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;

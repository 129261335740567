import axios from "axios";
import { NavBar } from "../components/navigation";
import { useEffect, useState } from "react";
import { BASE_URL } from "../constants";
import { useParams } from "react-router-dom";
import { getUser } from "../utils";
export const FishGallery = () => {
  const [photos, setPhotos] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [typeData, setTypeData] = useState<any>();
  const { id } = useParams<string>();
  const user = getUser();
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

  const saveImages = async () => {
    let formData = new FormData();
    formData.append("photos", photos);
    formData.append("typeId", id || "0");
    let results = await axios.post(`${BASE_URL}/fishtype/photos`, formData);
    console.log(results);
  };

  const getFishPhotos = async () => {
    try {
      setIsLoading(true);
      let results = await axios.get(`${BASE_URL}/fishtype/typephoto/${id}`);
      if (results.data.status) {
        setTypeData(results.data.fish[0]);
      }
      console.log(results);
    } catch (ex) {}
  };

  const removePicture = (id: number) => {
    setTypeData({
      ...typeData,
      photos: typeData.photos.filter((fd: any) => {
        return fd.id !== id;
      }),
    });
  };

  useEffect(() => {
    getFishPhotos();
  }, []);
  //this param should be a number, and number should be valid one
  if (!id) {
    return (
      <div>
        <h2>An error</h2>
      </div>
    );
  }
  if (!typeData) {
    return (
      <div>
        <h2>Please wait</h2>
      </div>
    );
  }
  return (
    <>
      <NavBar />
      <div className="h-100 w-100">
        <div className="container my-2">
          <div className="row">
            <div className="col-sm-10">
              <input
                type="file"
                onChange={(e: any) => setPhotos(e.target.files[0])}
              />
              <button onClick={saveImages}>Save</button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3 row-cols-sm-3 g-4">
            {typeData.photos.map((data: any) => (
              <div className="col">
                <TypePhoto photo={data} removeFromList={removePicture} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const TypePhoto = (props: any) => {
  const [deleting, setDeleting] = useState(false);
  const {
    photo: { path, id },
    removeFromList,
  } = props;

  const deletePhoto = async (id: any) => {
    setDeleting(true);
    try {
      await axios.delete(`${BASE_URL}/fishtype/delete-photo/${id}`);
      removeFromList(id);
    } catch (ex) {
    } finally {
      setDeleting(false);
    }
  };
  return (
    <div className="card">
      <img
        src={`${BASE_URL}/${path}`}
        className="card-img-top"
        height={200}
        alt="..."
      />
      <div className="card-body">
        {/* <h5 className="card-title">Card title</h5> */}
        {/* <p className="card-text">
      This is a longer card with supporting text below as a
      natural lead-in to additional content. This content is a
      little bit longer.
    </p> */}
        <button
          className="btn btn-danger btn-sm"
          onClick={() => deletePhoto(id)}
        >
          {deleting ? "deleting..." : "delete"}
        </button>
      </div>
    </div>
  );
};

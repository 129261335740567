import { Navigate } from "react-router-dom";
import { getUser } from "../utils";
import axios from "axios";
export const ProtectedRoute = ({ children }: any) => {
  const user = getUser();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/admin" />;
  }
  console.log(user);
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

  return children;
};

export const UnProtectedRoute = ({ children }: any) => {
  const user = getUser();
  if (user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

import { NavLink } from "react-router-dom";
import { NavBar } from "../components/navigation";
import { MagnifyGlass } from "../components/loaders";
import { COLORS } from "../constants";

export function Error404() {
  return (
    <>
      <NavBar />
      <div className="d-flex pt-5 align-items-center vh-100 flex-column">
        <MagnifyGlass />
        <h2 style={{ fontSize: 5 + "em", color: COLORS.BRAND }}>404,</h2>
        <p className="wf-bold" style={{ fontSize: 1.2 + "em" }}>
          Oops... someone is lost, <NavLink to="/">go home.</NavLink>
        </p>
      </div>
    </>
  );
}

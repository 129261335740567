import { COLORS } from "../constants";
export const Footer = ({ data }: any) => {
  let year = new Date().getFullYear();
  return (
    <footer
      className={`container-fluid ${
        data ? "d-flex justify-content-center" : ""
      }`}
      style={{ backgroundColor: data ? COLORS.BRAND : COLORS.BLACK }}
    >
      {data ? (
        <div className="py-3 px-5">
          <div>
            <img
              src={require("../assets/full-new-logo.png")}
              alt="logo"
              height="60px"
            />
          </div>
        </div>
      ) : (
        <div className="text-light py-2 px-2" style={{ fontSize: 0.6 + "em" }}>
          TrackIDV @ {year}
        </div>
      )}
    </footer>
  );
};

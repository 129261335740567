import clsx from "clsx";
import { COLORS } from "../constants";
import { getUser } from "../utils";
export const Identification = () => {
  let { company } = getUser();
  return (
    <div>
      <h4 className={clsx("py-0 my-0")} style={{ color: COLORS.ORANGE }}>
        Fish Types
      </h4>
      <p className="py-0 my-0">
        <small className="text-muted">Manage fish types</small>
      </p>
    </div>
  );
};

import axios from "axios";
import { NavBar, NavBarCenter } from "../components/navigation";
import { useState, useEffect } from "react";
import { ASSETS_URL, BASE_URL, COLORS } from "../constants";
import { useParams } from "react-router-dom";
import { Footer } from "../components/footer";
import QRCode from "react-qr-code";

export const Identity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [error, setError] = useState<any>();
  const { code } = useParams();
  const retrieveUser = async () => {
    setIsLoading(true);
    try {
      setError(undefined);
      let result = await axios.get(`${BASE_URL}/id/${code}`);
      if (result.data.status) {
        setId(result.data.identity);
      }
    } catch (ex) {
      console.log(ex);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    retrieveUser();
  }, []);
  return (
    <>
      <NavBarCenter data={id} />
      <div className="vh-100 d-flex align-items-center mt-3 flex-column">
        {id ? (
          <>
            {" "}
            <h3 className="fw-bold" style={{ color: COLORS.ORANGE }}>
              Identity Verified
            </h3>
            <img
              src={`${ASSETS_URL}/storage/images/${id.identity_photo}`}
              alt={id.identity_name}
              className="img-fallback"
              style={{ width: 180, borderRadius: 50 + "%" }}
            />
            <h3>{id.identity_name}</h3>
            <span style={{ fontWeight: 500, fontSize: 18 + "px" }}>
              {id.identity_position}
            </span>
            <span>ID No.:{id.identity_reference}</span>
            <QRCode
              value={`https://${id.company_domain}/${id.identity_uuid}`}
              size={80}
            />
            <span>{id.identity_uuid}</span>
          </>
        ) : null}
      </div>

      <Footer data={id} />
    </>
  );
};

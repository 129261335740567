import styled from "styled-components";
import { ASSETS_URL, COLORS } from "../constants";
import { getUser, removeUser } from "../utils";
import { NavLink, useNavigate } from "react-router-dom";

export const NavBar = ({ data }: any) => {
  const navigate = useNavigate();
  const { user } = getUser();
  const logout = () => {
    removeUser();
    navigate("/admin");
  };
  return (
    <StyledNav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid mx-4">
        {!data ? (
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/full-new-logo.png")}
              alt="logo"
              height="60px"
            />
          </NavLink>
        ) : (
          <NavLink className="navbar-brand" to="/">
            {data.company_logo ? (
              <img
                src={`${ASSETS_URL}/storage/images/${data.company_logo}`}
                alt="logo"
                height="60px"
              />
            ) : (
              <h3 className="text-light text-uppercase">{data.company_name}</h3>
            )}
          </NavLink>
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink to="/users" className="nav-link text-white">
                Users
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/orders" className="nav-link text-white">
                Orders
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link disabled"
                href="#"
                tabIndex={-1}
                aria-disabled="true"
              >
                Disabled
              </a>
            </li> */}
          </ul>
          {data ? null : (
            <span
              className="d-flex text-light"
              onClick={logout}
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-lock"></i> Logout ( {user.user_name} )
            </span>
          )}
        </div>
      </div>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  background-color: ${COLORS.BRAND};
`;

export const NavBarCenter = ({ data }: any) => {
  if (!data) return null;
  return (
    <div
      className="container-fluid d-flex justify-content-center align-items-center py-2"
      style={{ backgroundColor: COLORS.BRAND }}
    >
      {!data.company_logo ? (
        <h2 className="text-white d-inline-block text-center">
          {data.company_name}
        </h2>
      ) : (
        <img
          src={`${ASSETS_URL}/storage/images/${data.company_logo}`}
          alt="logo"
          height="60px"
        />
      )}
    </div>
  );
};

import { useState } from "react";
import styled from "styled-components";
import { BASE_URL, COLORS } from "../constants";
import axios from "axios";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { storeUser } from "../utils";
import { useNavigate } from "react-router-dom";
let validationSchema = Yup.object({
  user_name: Yup.string().required("Username is required!"),
  user_pass: Yup.string().required("Password is required!"),
});
export default function Login() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState<string | undefined>();
  let year = new Date().getFullYear();

  const tryLogin = async (e: any) => {
    let { user_pass: password, user_name: email } = e;
    setError(undefined);
    setIsLogin(true);
    try {
      let results = await axios.post(`${BASE_URL}/auth/login`, {
        password,
        email,
      });
      if (results.data.status) {
        let { user, accessToken: token, company } = results.data;
        storeUser({ user, token, company });
        //redirect to dashboard right now
        navigate("/");
      }
    } catch (ex: any) {
      console.log(ex);
      if (ex.response.data) {
        setError(ex.response.data.msg);
      }
    } finally {
      setIsLogin(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_pass: "",
    },
    onSubmit: tryLogin,
    validationSchema,
  });
  return (
    <LoginContent className="">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h3 className="mb-3" style={{ color: COLORS.ORANGE }}>
              Wavuvi Dashboard
            </h3>
            <div className="bg-white shadow rounded">
              <div className="row">
                <div className="col-md-7 pe-0">
                  <div className="form-left h-100 py-5 px-5">
                    <form className="row g-4">
                      <div className="col-12">
                        <label>
                          Username<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Icon className="bi bi-person-fill" />
                          </div>
                          <input
                            type="text"
                            className={clsx("form-control", {
                              "is-invalid":
                                formik.errors.user_name &&
                                formik.touched.user_name,
                            })}
                            placeholder="Enter Username"
                            value={formik.values.user_name}
                            onChange={formik.handleChange("user_name")}
                            onBlur={formik.handleBlur("user_name")}
                            autoComplete="off"
                          />
                        </div>
                        {formik.errors.user_name && formik.touched.user_name ? (
                          <div className="text-danger">
                            {formik.errors.user_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <label>
                          Password<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Icon className="bi bi-lock-fill" />
                          </div>
                          <input
                            type="password"
                            className={clsx("form-control", {
                              "is-invalid":
                                formik.errors.user_pass &&
                                formik.touched.user_pass,
                            })}
                            placeholder="Enter Password"
                            value={formik.values.user_pass}
                            onChange={formik.handleChange("user_pass")}
                            onBlur={formik.handleBlur("user_pass")}
                            autoComplete="off"
                          />
                        </div>
                        {formik.errors.user_pass && formik.touched.user_pass ? (
                          <div className="text-danger">
                            {formik.errors.user_pass}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="col-sm-6">
                        <a href="#" className="float-end text-primary">
                          Forgot Password?
                        </a>
                      </div> */}
                      {error ? (
                        <div className="col-12 text-danger">{error}</div>
                      ) : null}
                      <div className="col-12">
                        <LoginButton
                          type="submit"
                          className="btn px-4 float-end mt-4"
                          onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                          }}
                        >
                          {isLogin ? "Checking..." : "Login"}
                        </LoginButton>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-5 ps-0 d-none d-md-block">
                  <SidePanel className="form-right h-100  text-white text-center pt-5">
                    <img
                      src={require("../assets/full-new-logo.png")}
                      alt="afritrak africa"
                      width="200"
                      className="mt-5"
                    />
                    {/* <h2 className="fs-1">Welcome Back!!!</h2> */}
                  </SidePanel>
                </div>
              </div>
            </div>
            <p
              className="text-end mt-2 fw-bold"
              style={{ color: COLORS.BRAND, fontSize: 0.7 + "em" }}
            >
              Wavuvi @ {year}
            </p>
          </div>
        </div>
      </div>
    </LoginContent>
  );
}

const Icon = styled.i`
  color: ${COLORS.BRAND};
`;
const LoginButton = styled.button`
  background-color: ${COLORS.BRAND};
  color: #fff;
  &:hover {
    &&& {
      color: ${COLORS.BRAND};
    }
    background-color: ${COLORS.PALE_BLUE};
  }
`;
const SidePanel = styled.div`
  background-color: ${COLORS.BRAND};
`;
const LoginContent = styled.div`
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
`;

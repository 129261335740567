export const key: string = "@afrikey@@TOKEN";
export const storeUser = (user: any) => {
  let stringedUser = JSON.stringify(user);
  localStorage.setItem(key, stringedUser);
};

export const getUser = () => {
  let user = localStorage.getItem(key);
  if (user) return JSON.parse(user);
  return null; //this user could be used
};

export const removeUser = () => localStorage.removeItem(key);

import { useEffect, useState, useRef } from "react";
import { NavBar } from "../components/navigation";
import axios from "axios";
import { ASSETS_URL, BASE_URL, COLORS } from "../constants";
import { Modal } from "../components/modals";
import { useFormik } from "formik";
import { CompanyType } from "../components/types";
import * as Yup from "yup";
import clsx from "clsx";
import {
  BallsLoader,
  LengthyLoader,
  SpinnerLg,
  SpinnerSm,
  TubeLoader,
} from "../components/loaders";
import { Footer } from "../components/footer";
import { Identification } from "../components/Identification";
import { NavLink } from "react-router-dom";

export type Identity = {
  company_id: number;
  identity_id: number;
  name: string;
  identity_photo: string;
  unitSize: string;
  price: string;
  status: string;
  identity_uuid: string;
  currency: string;
};
type IdentityItem = Identity & CompanyType;
type FishType = {
  id?: number;
  name: string;
  price: number;
  status: string;
  currency: string;
  unitSize: number;
};
export const Identities = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [types, setType] = useState<FishType[]>([]);
  const removeModal = useRef<HTMLButtonElement>(null);
  const [edit, setEdit] = useState<FishType | undefined>();
  let validation = Yup.object({
    name: Yup.string()
      .min(4, "Name should be atleast 4 charachers")
      .required("Name is required"),
    unitSize: Yup.number()
      // .min(2, "Position should be atleast 2 charachers")
      .required("Unit size is required"),
    price: Yup.number().required("Price is required"),
    currency: Yup.string().required("Select a payment currency"),
  });

  const getIdenties = async () => {
    setIsLoading(true);
    try {
      let results = await axios.get(`${BASE_URL}/fishtype/all`);
      console.log(results);

      if (results.data.status) {
        setType(results.data.types);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const replaceIdentity = (id: FishType) => {
    setType(
      types.map((idd) => {
        if (idd.id !== id.id) {
          return idd;
        }
        return id;
      })
    );
  };

  const onSubmit = async (e: any) => {
    //PERFORM VALIDATION FOR THE IMAGE
    setIsCreating(true);
    let formData = new FormData();
    formData.append("name", e.name);
    formData.append("unitSize", e.unitSize);
    formData.append("price", e.price);
    formData.append("status", e.status);
    formData.append("currency", e.currency);
    // formData.append("identity_photo", image);
    try {
      let results = edit
        ? await axios.put(`${BASE_URL}/fishtype/update/${edit.id}`, e)
        : await axios.post(`${BASE_URL}/fishtype/add`, e);

      if (edit) {
        if (results.data.status) {
          replaceIdentity(results.data.fishtype);
        }
      } else {
        //add to a row
        if (results.data.status) {
          setType([results.data.fish, ...types]);
        }
      }
      if (removeModal.current) {
        removeModal.current.click();
      }
      formik.resetForm();
    } catch (ex: any) {
    } finally {
      setIsCreating(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: edit ? edit.name : "",
      unitSize: edit ? edit.unitSize : "",
      price: edit ? edit.price : "",
      status: edit ? edit.status : "Pending",
      currency: edit ? edit.currency : "TZS",
    },
    onSubmit,
    validationSchema: validation,
    enableReinitialize: true,
  });

  useEffect(() => {
    getIdenties();
  }, []);

  return (
    <>
      <NavBar data={undefined} />

      {!isLoading ? (
        <>
          <Modal title="New Fish Types" modalRef={removeModal}>
            {/* START OF IDENTITY CREATION FORM */}

            <form className="row g-3">
              <div className="col-12">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className={clsx("form-control", {
                    "is-invalid": formik.errors.name && formik.touched.name,
                  })}
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                  onBlur={formik.handleBlur("name")}
                  placeholder="eg. Prawns"
                  autoComplete="off"
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="col-md-3 ">
                <label htmlFor="currency" className="form-label">
                  Currency
                </label>
                <select
                  id="currency"
                  className={clsx("form-control", {
                    "is-invalid":
                      formik.errors.currency && formik.touched.currency,
                  })}
                  onChange={formik.handleChange("currency")}
                  onBlur={formik.handleBlur("currency")}
                  value={formik.values.currency}
                >
                  <option selected value="TZS">
                    TZS
                  </option>
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                </select>
                {formik.errors.status && formik.touched.status ? (
                  <div className="invalid-feedback">{formik.errors.status}</div>
                ) : null}
              </div>
              <div className="col-md-5">
                <label htmlFor="inputCity" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  className={clsx("form-control", {
                    "is-invalid": formik.errors.price && formik.touched.price,
                  })}
                  value={formik.values.price}
                  onChange={formik.handleChange("price")}
                  onBlur={formik.handleBlur("price")}
                  autoComplete="off"
                  placeholder="2500"
                  id="inputCity"
                />
                {formik.errors.price && formik.touched.price ? (
                  <div className="invalid-feedback">{formik.errors.price}</div>
                ) : null}
              </div>

              <div className="col-md-4">
                <label htmlFor="position" className="form-label">
                  Unit size ( Kg )
                </label>
                <input
                  type="number"
                  className={clsx("form-control", {
                    "is-invalid":
                      formik.errors.unitSize && formik.touched.unitSize,
                  })}
                  placeholder="2.5"
                  id="position"
                  onChange={formik.handleChange("unitSize")}
                  onBlur={formik.handleBlur("unitSize")}
                  value={formik.values.unitSize}
                />
                {formik.errors.unitSize && formik.touched.unitSize ? (
                  <div className="invalid-feedback">
                    {formik.errors.unitSize}
                  </div>
                ) : null}
              </div>

              <div className="col-md-3 ">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  id="status"
                  className={clsx("form-control", {
                    "is-invalid": formik.errors.status && formik.touched.status,
                  })}
                  onChange={formik.handleChange("status")}
                  onBlur={formik.handleBlur("status")}
                  value={formik.values.status}
                >
                  <option selected value="Pending">
                    Pending
                  </option>
                  <option value="On Market">On Market</option>
                </select>
                {formik.errors.status && formik.touched.status ? (
                  <div className="invalid-feedback">{formik.errors.status}</div>
                ) : null}
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-warning"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {isCreating ? (edit ? "Updating..." : "Saving...") : "Save"}
                </button>
              </div>
            </form>

            {/* END OF IDENTITY CREATION FORM */}
          </Modal>
          <div className="vh-100 d-flex flex-column">
            <div className="container mt-5 flex-grow-1">
              <div className="row">
                <div
                  className={`col-12 d-flex 
             justify-content-between 
             align-items-center  
             py-3 px-1 mb-4 `}
                >
                  <Identification />
                  <button
                    className="btn text-light"
                    style={{ backgroundColor: COLORS.ORANGE }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => {
                      setEdit(undefined);
                    }}
                  >
                    New Fish Type
                  </button>
                </div>
              </div>
              <div className="row shadow rounded bg-light">
                <div className="col-12 p-3">
                  <table className="table table-hover">
                    <thead>
                      <tr className="table-war">
                        <th scope="col">#</th>
                        {/* <th scope="col">ID</th> */}
                        {/* <th scope="col">Reference</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Unit Size</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-light">
                      {types.map((id: FishType, idx) => (
                        <tr key={id.id}>
                          <th scope="row">{idx + 1}</th>
                          {/* <td>{id.identity_uuid}</td> */}
                          <td>{id.name}</td>
                          <td>
                            {id.price} {id.currency}{" "}
                          </td>
                          <td>{id.unitSize}</td>

                          {/* <td>
                            <img
                              src={`${ASSETS_URL}/storage/images/${id.identity_photo}`}
                              alt={id.identity_photo}
                              width="60"
                            />
                          </td> */}
                          <td>
                            <span
                              className="d-inline-block px-2 text-uppercase fw-bolder"
                              style={{
                                fontSize: 0.7 + "em",
                                padding: 1 + "px",
                                borderRadius: 4 + "px",
                                backgroundColor:
                                  id.status === "Pending"
                                    ? COLORS.GRAY
                                    : COLORS.PALE_GREEN,

                                color:
                                  id.status === "Pending"
                                    ? "white"
                                    : COLORS.GREEN,
                              }}
                            >
                              {id.status}
                            </span>
                          </td>
                          <td
                            className="align-center justify-space-center"
                            style={{ height: 100 + "%" }}
                          >
                            <div className="d-flex align-items-center">
                              <i
                                className="bi bi-pencil-fill text-warning"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                  setEdit(id);
                                }}
                                style={{ fontSize: 15, cursor: "pointer" }}
                              ></i>
                              <NavLink to={`/fish-gallery/${id.id}`}>
                                <i
                                  className="bi bi-link text-info"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: 1.4 + "em",
                                  }}
                                ></i>
                              </NavLink>
                            </div>
                            {/* Dropend button ended */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
          <TubeLoader />
        </div>
      )}
    </>
  );
};

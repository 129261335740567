import { useEffect, useState } from "react";
import { Footer } from "../components/footer";
import { TubeLoader } from "../components/loaders";
import { NavBar } from "../components/navigation";
import axios from "axios";
import { BASE_URL } from "../constants";

export const FishOrders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ordersList, setOrders] = useState([]);
  const retrieveUsers = async () => {
    setIsLoading(true);
    try {
      let results = await axios.get(`${BASE_URL}/orders/all`);
      if (results.data.status) {
        setOrders(results.data.orders);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    retrieveUsers(); //clean ups is needed soo much
  }, []);
  return (
    <>
      <NavBar data={undefined} />

      {!isLoading ? (
        <>
          <div className="vh-100 d-flex flex-column">
            <div className="container mt-5 flex-grow-1">
              <div className="row shadow rounded bg-light">
                <div className="col-12 p-3">
                  <table className="table table-hover">
                    <thead>
                      <tr className="table-war">
                        <th scope="col">#</th>
                        {/* <th scope="col">ID</th> */}
                        {/* <th scope="col">Reference</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Role</th>
                        <th scope="col text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-light">
                      {ordersList.map((id: any, idx: number) => (
                        <tr key={id.id}>
                          <th scope="row">{idx + 1}</th>
                          {/* <td>{id.identity_uuid}</td> */}
                          <td>{id.name}</td>
                          <td>{id.email}</td>
                          <td>{id.phone}</td>
                          <td>{id.role}</td>
                          <td>
                            <i className="bi bi-back text-warning"></i>
                          </td>
                          {/* <td>
                                <img
                                  src={`${ASSETS_URL}/storage/images/${id.identity_photo}`}
                                  alt={id.identity_photo}
                                  width="60"
                                />
                              </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
          <TubeLoader />
        </div>
      )}
    </>
  );
};

export const BASE_URL = "https://api.wavuvi.co.tz";
export const ASSETS_URL = "https://api.wavuvi.co.tz";

// export const BASE_URL = "http://127.0.0.1:8000";
// export const ASSETS_URL = "http://127.0.0.1:8000";

export const COLORS = {
  BRAND: "#22578A",
  TRUE_RED: "#f41018",
  PALE_RED: "#f7bbbf",
  SEMI_PALE_RED: "#ec5a62",
  ORANGE: "#F68821",
  PALE_GREEN: "#d9fbd0",
  GREEN: "#1c6c09",
  GENERAL_BACKGROUND: "#FFFCE8",
  PALE_BLUE: "#3F88C5",
  GRAY: "#7c90a6",
  BLACK: "#0A0A08",
};
